.login-logo {
  width:10%;
}

.header-logo {
  width:5%;
}

.pro-sidebar-inner, .pro-inner-list-item, .popper-inner{
  background: #f8f9fa !important;
}

.pro-icon-wrapper{
  background: #2388da !important;
}

.pro-inner-list-item{
  color:#646363 !important;
}

.pro-sidebar{
  color: #ffffff !important;
}

.prosidebar-submenu-item {
  background: transparent;
  border: transparent;
  color:#646363;
}

.prosidebar-submenu-item:hover{
  color: #9b9797;
}

.prosidebar-footer-text{
  font-size: 0.4rem;
  color:  #9b9797;
}

.text-header{
  color:  #646363;
}

.capitalize {
  text-transform: capitalize;
}

.slick-thumb li{
  width: 60px !important;
}

.slick-prev:before, .slick-next:before {
  color: #007bff !important;
}

.slick-prev-closer{
  left: -25px !important;
}

.categoria-slider-img{
  margin: auto;
}

.action-button {
  border-color: transparent ;
  padding: 0;
}

.custom-upload{
  opacity: 0;
  position: absolute;
  z-index: -1;
  height: 0px !important;
}

.custom-upload-label{
  cursor: pointer;
}

.simple-hr{
  margin-bottom: 0;
}

.dashboard-resumen-principal-text {
  font-size: x-large;
  color: white;
}

.dashboard-resumen-footer-text {
  font-size: x-small;
  color: white;
}

.dashboard-resumen-background-azul {
  background-color: #0096d5;
}

.dashboard-resumen-background-azul-dark {
  background-color: #1f7cc0;
}

.dashboard-resumen-background-yellow {
  background-color: #fdd900;
}

.dashboard-resumen-background-yellow-dark {
  background-color: #f6c300;
}

.dashboard-resumen-background-green {
  background-color: #009e3c;
}

.dashboard-resumen-background-green-dark {
  background-color: #007937;
}

.dashboard-resumen-background-red {
  background-color: #e30615;
}

.dashboard-resumen-background-red-dark {
  background-color: #d31116;
}

/* .CircularProgressbar-text{
  font-size: 2.5rem;
} */

.CircularProgressbar-custom-list {
  width: 50% !important;
}

.add-elementos-button {
  border-radius: 10%;
  box-shadow: 0px 2px 5px silver;
}

@media (max-width: 1048px) {
  .dashboard-resumen-principal-text {
    font-size:medium;
  }
  .CircularProgressbar-text{
    font-size: 2rem ;
  }
}

@media (max-width:992px) {
  .CircularProgressbar-custom{
    width: 40% !important;
  }
  .CircularProgressbar-custom-list {
    width: 100% !important;
  }
}

@media (max-width: 800px) {
  .dashboard-resumen-principal-text {
    font-size:small;
  }
  .CircularProgressbar-custom{
    width: 70% !important;
  }
}

@media (max-width: 768px) {
  .dashboard-resumen-principal-text {
    font-size:x-large;
  }
  .categoria-slider-img{
    width: 100%;
  }
}